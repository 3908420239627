export class Component{
    /**
     * Creates a new component
     * @param {string} scope
     * @param {jQuery} $root
     */
    constructor(scope,$root){
        this.scope = scope;
        this.$root = $root;
        this.$root.data("component",this);
    }

    toJSON(){ //avoid conversions to json
        return undefined;
    }

    /**
     * Gets member based on scope
     * @param {string|string[]} memberClass Class name after [scope]__
     * @returns {jQuery}
     */
    findMember(memberClass){
        if(this.$root){
            let selector = this.scope+"__"+memberClass;
            if($.isArray(memberClass)){
                let l = [];
                for(let i in memberClass){
                    l.push(this.scope+"__"+memberClass[i]);
                }
                selector = l.join(",");
            }
            return this.$root.find(selector);
        }else{
            throw "Component is not ready yet";
        }
    }

    /**
     * Applies binding action to current component
     * @param {string} action
     * @param {any} binding
     */
    applyBindingAction(action,binding){
        switch(action){
            case "show":
            case "hide":
                this.$root.toggle(action=="show");
                break;
        }
    }

    ready(){
        //component level ready function
    }
}

//extends jquery for component method
jQuery.fn.extend(
    {
        /**
         * @returns {Component}
         */
        "component": function(){
            return this.data("component");
        }
    }
);