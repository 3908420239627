/** GVF functions */
import {GvfConfigService} from "./gvf-config-service";

class GvfService{
    constructor(){
        this.currentTTimemout = null;
    }

    /**
     * Gets JSON from object
     * @param {*} obj
     * @returns {string}
     */
    toJson(obj){
        if($.isPlainObject(obj) || $.isArray(obj)){
            return JSON.stringify(obj);
        }else{
            return obj;
        }
    }

    /**
     * Reads json string
     * @param {string} obj
     * @returns {Object}
     */
    parseJson(obj){
        if(obj && obj.indexOf && (obj.indexOf("{")> -1 || obj.indexOf("[")> -1)){
            try{
                return JSON.parse(obj);
            }catch(e){
                return obj;
            }
        }else{
            return obj;
        }
    }

    /**
     * Clones object
     * @param {Object} objectInstance
     * @return {Object}
     */
    clone(objectInstance){
        return Object.assign(Object.create(Object.getPrototypeOf(objectInstance)), objectInstance);
    }

    /**
     * Loads given script
     * @param {string} jsUrl
     * @return {jQuery.Promise}
     */
    loadJsScript(jsUrl){
        return new Promise((resolve,reject) => {
            $.ajax({
                method: "GET",
                url: jsUrl,
                dataType: "script",
                success: function(data){
                    resolve(data);
                },
                error: function(error){
                    reject(error);
                }
            });
        });
    }

    parseUrlParams(params){
        if(params && params.length>0){
            const assigns = params.split("&");
            const object = {};
            for(let i = 0; i<assigns.length; i++){
                const assign = assigns[i].split("=");
                object[assign[0]] = decodeURIComponent(assign[1]);
            }
            return object;
        }else{
            return {};
        }
    }

    /**
     * Calls a remote endpoint
     * @param {string} path
     * @param {Object} params
     * @param {string?} method
     * @return {Promise<any>}
     */
    endpoint(path,params,method){
        if(path.indexOf("/ep")== -1){
            path = "/ep"+path;
        }
        method = method?method.toUpperCase():"POST";
        if(method!="GET"){
            params = this.toJson(params);
        }
        const apiRootUrl = GvfConfigService.getValue("apiRootUrl");

        return new Promise((resolve,reject) => {
            $.ajax(
                apiRootUrl+path,
                {
                    type: method,
                    url: apiRootUrl+path,
                    contentType: "application/json",
                    data: params,
                    cache: false,
                    processData: (method=="GET"),
                    success: function(data){
                        resolve(data);
                    },
                    error: function(error){
                        reject(error);
                    }
                }
            );
        });
    }

    /**
     * Delays execution
     * @param {int} milliseconds
     * @param {string?} key
     * @return {Promise}
     */
    delay(milliseconds,key){
        if(key){
            this.cancelDelay(key);
        }

        return new Promise(
            (resolve) => {
                let tt = setTimeout(
                    () => {
                        resolve();
                    },
                    milliseconds
                );
                if(key){
                    this.tTimeout(key,tt);
                }
            }
        );
    }

    tTimeout(key,value){
        if(!this.currentTTimemout){
            this.currentTTimemout = {};
        }
        if(value===undefined){
            return this.currentTTimemout[key];
        }else{
            this.currentTTimemout[key] = value;
        }
    }

    /**
     * Cancels delay
     * @param {string} key
     * @return {int}
     */
    cancelDelay(key){
        let tt = this.tTimeout(key);
        if(tt){
            clearTimeout(tt);
        }
    }
}

const s_GvfService = new GvfService();
export {s_GvfService as GvfService};
