import {Component} from "../../../gvf/js/component";
import {GvfService} from "../../../gvf/js/gvf-service";
import {UtilsService} from "../../js/utils-service";
import {ObjectService} from "../../js/object-service";
import {FcmService} from "../../js/fcm-service";
import {GvfUiService} from "../../../gvf/js/gvf-ui-service";

export class BodyComponent extends Component{
    constructor(scope,$root){
        super(scope,$root);
        this.zIndex = 1050;
    }

    static toastNotification(heading,text,icon){
        const options = {
            heading: heading,
            text: text,
            position: "top-right",
            hideAfter: 10000,
            loaderBg: "#ff6849",
            icon: icon,
            afterHidden: function(){
                GvfService.endpoint(
                    "/cp/cp-toast-notification-detail/get-notification",
                    {}
                ).then((resp) => {
                    if(resp.id){
                        BodyComponent.toastNotification(resp.heading,resp.message,resp.icon);
                    }
                });
            }
        };
        $.toast(options);
    }

    ready(){
        if(this.$root.data("popup-message")){
            swal(
                {
                    type: "info",
                    title: "",
                    text: this.$root.data("popup-message")
                }
            );
        }

        if(this.$root.data("toast-message")){
            BodyComponent.toastNotification(this.$root.data("toast-heading"),this.$root.data("toast-message"),this.$root.data("toast-icon"));
        }

        this.findMember("logout").click(
            (ev) => {
                ev.preventDefault();
                $(ev.currentTarget).addClass("is-loading");
                GvfService.endpoint(
                    "/cp/cp/logout",
                    {}
                ).then(
                    (response) => {
                        window.location = "/";
                    }
                ).catch(
                    (response) => {
                        UtilsService.alertEndpointResponse(response);
                    }
                ).finally(
                    () => {
                        $(ev.currentTarget).removeClass("is-loading");
                    }
                );
            }
        );

        //modal links functionality
        GvfUiService.ready("a[href*='#modal-']",($element) => {
            $element.click(
                (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    const $link = $(ev.currentTarget);
                    const parts = $(ev.currentTarget).attr("href").split("#modal-");
                    const params = GvfService.parseUrlParams(parts[0].split("?")[1]);
                    const model = parts[1];
                    const objectService = new ObjectService(model.replace(/-/g,"_"));
                    objectService.openDetailModal({
                        objectId: params.id,
                        editMode: params.edit==1,
                        lang: params.lang,
                        saveCallback: () => {
                            $link.trigger("object:updated");
                        },
                        deleteCallback: () => {
                            $link.trigger("object:deleted");
                        }
                    });
                }
            );
        });
        //tooltips functionality
        GvfUiService.ready("[data-toggle=\"tooltip\"]",($element) => {
            $element.tooltip();
        });

        //Nested modals scroll fix
        $(document).on(
            "hide.bs.modal",
            ".modal",
            () => {
                this.zIndex--;
                if($(".modal:visible").length>0){
                    $(document.body).addClass("modal-open");
                }
                $(".modal-backdrop").css("z-index",this.zIndex-1);
            }
        );
        $(document).on(
            "show.bs.modal",
            ".modal",
            (ev) => {
                this.zIndex++;
                $(ev.target).css("z-index",this.zIndex);
            }
        );
        $(document).on(
            "shown.bs.modal",
            ".modal",
            (ev) => {
                $(".modal-backdrop").css("z-index",this.zIndex-1);
            }
        );

        if(this.$root.data("firebase-vapid")){
            FcmService.init(this.$root.data("firebase-vapid"));
        }
    }
}
