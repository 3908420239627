import {GvfService} from "./gvf-service";

class GvfUiService{
    constructor(){
        this.funcInit = [];
        this.initExecuted = false;

        $(document).ready(
            () => {
                this.init();
                this.initExecuted = true;
            }
        );

        //extend functionality for forms
        let classRef = this;
        jQuery.fn.extend(
            {
                "getValues": function(){
                    return classRef.getValues(this);
                },
                "reportValidity": function(){
                    return classRef.reportValidity(this);
                }
            }
        );
    }

    /**
     * Registers delayed component with import
     * @param {string} scope Scope in css selector notation
     * @param {Function} importFunction
     * @return {any}
     */
    registerDelayedComponent(scope,importFunction){
        this.ready(
            scope,
            ($element) => {
                importFunction().then(
                    (param)=>{
                        //get component value in object
                        for(let i in param){
                            if(param.hasOwnProperty(i)){
                                this.componentReadyCall(scope,param[i],$element);
                                return;
                            }
                        }
                    }
                );
            },
            scope.length //bigger class name have more priority in init components
        );
    }

    /**
     * Registers component
     * @param {string} scope Scope in css selector notation
     * @param {function} component Component's class
     * @param {int?} priority Bigger priority ready triggers first
     */
    registerComponent(scope,component,priority=0){
        this.ready(
            scope,
            ($element) => {
                this.componentReadyCall(scope,component,$element);
            },
            priority>0?priority:component.name.length //bigger class name have more priority in init components
        );
    }

    /**
     * Internal call to ready on component
     * @param {string} scope Scope in css selector notation
     * @param {function} component Component's class
     * @param {jQuery} $element Element
     */
    componentReadyCall(scope,component,$element){
        const previousComponent = $element.component();
        //$element not initialized or given component is instance of $element component (never call ready on super classes)
        if(!previousComponent || previousComponent.constructor.name.length<component.name.length){
            const object = new component(scope,$element);
            object.ready();
        }
    }

    /**
     *
     * @param {string} jqSelector
     * @param {function?} f
     * @param {int?} priority
     */
    ready(jqSelector,f,priority){
        if(jqSelector.indexOf(",")== -1){
            const func = {"func": f,"jqSelector": jqSelector,"priority":priority??0};
            this.funcInit.push(func);
            //sort by priority
            this.funcInit.sort((a,b) => (a.priority > b.priority) ? -1 : ((b.priority > a.priority) ? 1 : 0));
            if(this.initExecuted){
                this.initBase(null,func);
            }
        }else{
            const jqSels = jqSelector.split(",");
            for(let i = 0; i<jqSels.length; i++){
                this.ready(jqSels[i],f);
            }
        }
    }

    readyBody(f){
        this.ready("body",f);
    }

    //Report validity on form
    reportValidity($form){
        if($form.is("form")){
            return $form.get(0).reportValidity();
        }else{
            return null;
        }
    }

    /**
     * Gets values from given jQuery form
     * @param {jQuery} $form
     * @returns {{}|null}
     */
    getValues($form){
        if($form.is("form")){
            //move away nested forms
            const $extract = $("<div />");
            const $itemsToExtract = $form.find(".js-form");
            $itemsToExtract.each(
                (index,item)=>{
                    $(item).data("parent",$(item).parent());
                    $(item).data("prev",$(item).prev());
                }
            );
            $extract.append($itemsToExtract);

            const values = $form.serializeArray();
            const formData = {};
            for(let i = 0; i<values.length; i++){
                const nom = values[i].name;
                const val = GvfService.parseJson(values[i].value);
                if(nom.substring(nom.length-2)=="[]"){
                    const k = nom.substring(0,nom.length-2);
                    if(!formData[k]){
                        formData[k] = [];
                    }
                    formData[k].push(val);
                }else{
                    formData[nom] = val;
                }
            }

            //restore nested forms
            $extract.find(".js-form").each(
                (index,item)=>{
                    const $item = $(item);
                    if($item.data("prev").length>0){
                        $item.data("prev").after($item);
                    }else{
                        $item.data("parent").prepend($item);
                    }
                }
            );

            return formData;
        }else{
            return null;
        }
    }

    /**
     * Initializes DOM
     * @param {jQuery?} $jq
     */
    init($jq){
        this.initBase($jq);
    }

    /**
     * @param {jQuery?} $jq
     * @param {Object?} funcExec
     */
    initBase($jq,funcExec){
        if($jq && $jq.length==0){
            return;
        }

        let functions;
        if(funcExec){
            functions = [funcExec];
        }else{
            functions = this.funcInit;
        }

        if(!$jq){
            $jq = $("body");
        }

        const isBody = $jq.is("body");
        const emptySelectors = [];
        let cItems = {};
        for(let i = 0; i<functions.length; i++){
            const func = functions[i].func;
            const jqSel = functions[i].jqSelector;
            if(func){
                if(cItems[jqSel]){
                    const itemsC = cItems[jqSel];
                    for(let jc = 0; jc<itemsC.length; jc++){
                        func(itemsC[jc]);
                    }
                }else{
                    let noItems = false;
                    for(let j = 0; j<emptySelectors.length; j++){
                        const selV = emptySelectors[j];
                        if(jqSel.length>selV.length && jqSel.indexOf(selV)===0 && "abcdefghijklmnopqrstuvwxyz-_".indexOf(jqSel.charAt(selV.length).toLowerCase())== -1){
                            noItems = true;
                            break;
                        }
                    }
                    if(noItems){
                        continue;
                    }
                    let jqF = null;
                    if($jq.is(jqSel)){
                        jqF = $jq.filter(jqSel);
                    }else{
                        jqF = $jq.find(jqSel);
                    }

                    const items = [];
                    if(jqF.length>0){
                        jqF.each(
                            (index,item)=>{
                                const $item = $(item);
                                items.push($item);
                                func($item);
                            }
                        );
                        if(items.length==0){
                            emptySelectors.push(jqSel);
                        }
                    }else if(!isBody){
                        const jqF2 = $(jqSel);
                        jqF2.each(
                            (index,item)=>{
                                if($jq.find(this).length>0){
                                    const $item = $(item);
                                    func($item);
                                    items.push($item);
                                }
                            }
                        );
                    }
                    cItems[jqSel] = items;
                }
            }
        }
    }
}

const s_GvfUiService = new GvfUiService();
export {s_GvfUiService as GvfUiService};