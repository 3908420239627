import {Component} from "../../../gvf/js/component";
import {GvfService} from "../../../gvf/js/gvf-service";

export class Form extends Component{
    constructor(scope,$root){
        super(scope,$root);
        this.oppositeAction = {
            show: "hide",
            hide: "show",
            enable: "disable",
            disable: "enable",
            require: "optional",
            optional: "require"
        };
    }

    /**
     * Sets field as required
     * @param $target
     * @param isRequired
     */
    setRequireField($target,isRequired){
        $target.component()?.setRequired(isRequired);
    }

    /**
     * Applies binding action to given element
     * @param {jQuery} $target
     * @param {string} action
     * @param {any?} binding applied binding object
     */
    applyFieldBindingAction($target,action,binding){
        if($target.component()){
            $target.component()?.applyBindingAction(action,binding);
        }else{
            const $field = $target.closest(".js-form-field");
            if($field.length>0){
                $field.component()?.applyBindingActionToChild($target,action,binding);
            }else{
                if(action=="show" || action=="hide"){
                    $target.toggle(action=="show");
                }
            }
        }
    }

    //Apply bindings response to given form
    applyBindings(bindingsResponse){
        const $form = this.$root;
        const appliedSelector = "[data-applied-binding]";
        let $appliedElements = $form.find(appliedSelector).not($form.find(this.scope+" "+appliedSelector));
        for(let i in bindingsResponse){
            const binding = bindingsResponse[i];
            //pick form fields and linked blocks
            //avoid nested fields
            const nameSelector = "[data-name='"+binding.field+"']";
            const bindAsSelector = "[data-bind-as='"+binding.field+"']";
            const $target = $form.find(nameSelector+","+bindAsSelector).not($form.find(this.scope+" "+nameSelector+","+this.scope+" "+bindAsSelector));
            $appliedElements = $appliedElements.not($target);
            $target.each(
                (index,item) => {
                    this.applyFieldBindingAction($(item),binding.action,binding);
                }
            );
            $target.attr("data-applied-binding",binding.action);
        }

        $appliedElements.each(
            (index,item) => {
                const $el = $(item);
                const previousAction = $el.attr("data-applied-binding");
                if(this.oppositeAction[previousAction]){
                    this.applyFieldBindingAction($el,this.oppositeAction[previousAction]);
                }
            }
        );
    }

    /**
     * Gets remote bindings and returns a promise when finished
     * @return Promise<Array>
     */
    checkRemoteBindings(){
        const remoteBinding = this.$root.data("remote-binding");
        if(remoteBinding){
            const formData = this.$root.getValues();
            const ps = [];
            for(let i in remoteBinding){
                ps.push(
                    GvfService.endpoint(remoteBinding[i].ep,{formData:formData,payload:remoteBinding[i].payload}).then(
                        (response)=>{
                            return response;
                        }
                    )
                );
            }
            return Promise.all(ps).then(
                (remoteBindingResponses)=>{
                    let bindings = [];
                    for(let i in remoteBindingResponses){
                        bindings = bindings.concat(remoteBindingResponses[i]);
                    }
                    return bindings;
                }
            );
        }else{
            return Promise.resolve([]);
        }
    }

    /**
     * Checks form bindings
     */
    checkBindings(){
        let bindings = this.getCurrentBindings();
        this.checkRemoteBindings().then(
            (remoteBindings)=>{
                this.applyBindings(bindings.concat(remoteBindings));
            }
        );
    }

    /**
     * Gets current applying bindings response from form
     * @return {object}
     */
    getCurrentBindings(){
        const $form = this.$root;
        const bindingsResponse = [];
        const selector = "[data-bind]";
        //avoid nested fields
        $form.find(selector).not($form.find(this.scope+" "+selector)).each(
            (index,item)=>{
                const $el = $(item);
                const bindings = $el.data("bind");
                const results = {};
                for(let bindingIndex in bindings){
                    const binding = bindings[bindingIndex];
                    let match = false;
                    if(binding.field){
                        const $other = $form.find("[data-name='"+binding.field+"']");
                        let val = $other.formFieldVal();
                        if(binding.values && $.isArray(binding.values)){
                            if(!$.isArray(val)){
                                val = [val];
                            }
                            for(let i = 0; i<val.length; i++){
                                let v = val[i];
                                if(v){
                                    if(binding.values.indexOf(v)> -1 || binding.values.indexOf(v.toString())> -1 || binding.values.indexOf(parseInt(v))> -1){
                                        match = true;
                                        break;
                                    }
                                }
                            }
                        }else if(binding.range && $.isArray(binding.range)){
                            if(
                                (binding.range[0]===null || val>=binding.range[0]) &&
                                (binding.range[1]===null || val<=binding.range[1])
                            ){
                                match = true;
                            }
                        }else if(binding.regexp){
                            const regexp = new RegExp(binding.regexp);
                            if(regexp.test(val)){
                                match = true;
                            }
                        }else{
                            match = !!val;
                        }

                        let name = $el.data("name");
                        if(!name){
                            name = $el.data("bind-as");
                        }

                        const action = (match?binding.action:this.oppositeAction[binding.action]);
                        bindingsResponse.push({"field": name,"action": action,"setvalue": binding.setvalue});

                        /* APPLY OPERATORS */
                        if(binding.operator){
                            const key = name+"_"+binding.action;
                            if(!results[key]){
                                results[key] = {result: match,items: [],action: binding.action};
                            }
                            //store current response index to access it later
                            results[key].items.push(bindingsResponse.length-1);
                            if(binding.operator=="or"){
                                results[key].result = results[key].result || match;
                            }else{
                                results[key].result = results[key].result && match;
                            }
                        }
                    }
                }

                //apply logic operators result
                for(let key in results){
                    for(let i in results[key].items){
                        bindingsResponse[results[key].items[i]].action = (results[key].result?results[key].action:this.oppositeAction[results[key].action]);
                    }
                }
            }
        );
        return bindingsResponse;
    }

    /**
     * Shows messages in form
     * @param {string[]} messages
     * @param {boolean} isError
     */
    showErrorMessages(messages,isError){
        const $form = this.$root;
        const $messages = $form.find(this.scope+"__messages:first");
        $messages.empty();
        if(messages.length>0){
            for(let i = 0; i<messages.length; i++){
                const $message = $("<div />").addClass("alert alert-"+(isError?"danger":"primary"));
                $message.html(messages[i]);
                $messages.append($message);
            }
            $form.addClass("is-showing-messages");
        }else{
            $form.removeClass("is-showing-messages");
        }
    }

    /**
     * Applies validation response to form
     * @param {object} validation
     */
    applyValidationResponse(validation){
        const $form = this.$root;
        if(validation && validation.validation){
            validation = validation.validation;
        }

        const errors = [];
        if(validation && validation.fields && validation.fields.length>0){
            for(let i = 0; i<validation.fields.length; i++){
                const selector = "[data-name='"+validation.fields[i].field+"']";
                const $field = $form.find(selector);
                //avoid nested fields
                $field.not($form.find(this.scope+" "+selector));

                const err = [];
                const info = validation.fields[i].info;
                for(let j = 0; j<info.length; j++){
                    err.push(info[j].contextualErrorMessage?info[j].contextualErrorMessage:info[j].errorMessage);
                    errors.push(info[j].errorMessage);
                }
                $field.formFieldShowError(err);
            }
        }
        if(validation.global && validation.global.length>0){
            for(let i = 0; i<validation.global.length; i++){
                errors.push(validation.global[i]);
            }
        }
        this.showErrorMessages(errors,true);
    }

    /**
     * Checks fields restrictions
     * @return {Promise}
     */
    checkRestrictions(){
        const $form = this.$root;
        const restrictionChecks = [];
        $form.find(".js-form-field").each(
            (index,item)=>{
                const $el = $(item);
                if($el.closest(this.scope).get(0)==$form.get(0)){ //avoid nested forms
                    restrictionChecks.push($el.formFieldCheckRestrictions(true));
                }
            }
        );
        return Promise.all(restrictionChecks).then(
            (results)=>{
                for(let i in results){
                    if(!results[i]){
                        return false;
                    }
                }
                return true;
            }
        );
    }

    ready(){
        this.$root.on(
            "formField:changeVal",
            (ev) => {
                GvfService.delay(300,"formChangeVal").then(
                    () => {
                        this.checkBindings();
                    }
                );
            }
        );

        this.checkBindings();
        if(this.$root.data("validation-response")){
            this.applyValidationResponse(this.$root.data("validation-response"));
        }
    }
}

const scope = ".js-form";
jQuery.fn.extend(
    {
        "getCurrentBindings": function(){
            if(this.is(scope)){
                return this.component().getCurrentBindings();
            }
        },
        /**
         * @return {Promise<boolean>}
         */
        "checkBindings": function(){
            if(this.is(scope)){
                this.component().checkBindings();
            }
        },
        /**
         * @return {Promise<boolean>}
         */
        "checkRestrictions": function(){
            if(this.is(scope)){
                return this.component().checkRestrictions();
            }
        },
        "applyValidationResponse": function(response){
            if(this.is(scope)){
                this.component().applyValidationResponse(response);
            }
        },
        "applyBindings": function(bindingsResponse){
            if(this.is(scope)){
                return this.component().applyBindings(bindingsResponse);
            }
        }
    }
);