/** GVF Config */
class GvfConfigService{
	constructor(){
		this.currentOptions = window.GvfConfigOptions;
	}

	/**
	 * Gets option var value
	 * @return {any}
	 */
	getValue(varName){
		if(this.currentOptions){
			if(this.currentOptions[varName]!==undefined){
				return this.currentOptions[varName];
			}else{
				throw "Config variable "+varName+" not defined";
			}
		}else{
			throw "Config class has not been initialised";
		}
	}
}

const s_GvfConfigService = new GvfConfigService();
export {s_GvfConfigService as GvfConfigService};